import React from "react";
import HeroBgAnimation from "../HeroBgAnimation";
import {
  HeroContainer,
  HeroBg,
  HeroLeftContainer,
  Img,
  HeroRightContainer,
  HeroInnerContainer,
  TextLoop,
  Title,
  Span,
  SubTitle,
  // ResumeButton,
  IconContainer,
  ButtonAndIconContainer,
} from "./HeroStyle";
import HeroImg from "../../images/HeroImage.jpg";
import Typewriter from "typewriter-effect";
import { Bio } from "../../data/constants";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GithubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";

const HeroSection = () => {
  return (
    <div id="about">
      <HeroContainer>
        <HeroBg>
          <HeroBgAnimation />
        </HeroBg>
        <HeroInnerContainer>
          <HeroLeftContainer id="Left">
            <Title>
              Hi, I am <br /> {Bio.name}
            </Title>
            <TextLoop>
              I am a
              <Span>
                <Typewriter
                  options={{
                    strings: Bio.roles,
                    autoStart: true,
                    loop: true,
                  }}
                />
              </Span>
            </TextLoop>
            <SubTitle>
              {Bio.description.map((item, index) => (
                <React.Fragment key={index}>
                  {item}
                  {index !== Bio.description.length - 1 && (
                    <>
                      <br />
                      <br />
                    </>
                  )}
                </React.Fragment>
              ))}
            </SubTitle>
            <ButtonAndIconContainer>
              {/* <ResumeButton
                href={Bio.resume}
                target="_blank">
                Check Resume
              </ResumeButton> */}
              <IconContainer>
                <LinkedInIcon
                  sx={{
                    fontSize: "3rem", // Set the size of the icon
                    cursor: "pointer", // Change the cursor on hover to indicate it's clickable
                    color: "#0077b5", // Set the specific LinkedIn blue color
                    transition: "all 0.2s ease-in-out !important",
                    "&:hover": {
                      transform: "scale(1.1)", // Enlarge the icon slightly on hover
                      filter: "brightness(1.1)", // Optionally brighten the icon on hover
                    },
                  }}
                  onClick={() => window.open(Bio.linkedin, "_blank")}
                />
                <EmailIcon
                  sx={{
                    fontSize: "3rem",
                    cursor: "pointer",
                    color: "#f4c542",
                    transition: "all 0.2s ease-in-out !important",
                    "&:hover": {
                      transform: "scale(1.1)", // Enlarge the icon slightly on hover
                      filter: "brightness(1.1)", // Optionally brighten the icon on hover
                    },
                  }}
                  onClick={() => (window.location.href = `mailto:${Bio.email}`)}
                />
                <GithubIcon
                  sx={{
                    fontSize: "2.4rem",
                    cursor: "pointer",
                    color: "white", // Default white color, adjust as necessary
                    borderRadius: "50px", // To match the button
                    transition: "all 0.2s ease-in-out !important",
                    background:
                      "linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%)",
                    boxShadow:
                      "20px 20px 60px #1f2634, -20px -20px 60px #1f2634",
                    "&:hover": {
                      transform: "scale(1.1)", // Enlarge the icon slightly on hover
                      filter: "brightness(1.1)", // Optionally brighten the icon on hover
                    },
                  }}
                  onClick={() => window.open(Bio.github, "_blank")}
                />
              </IconContainer>
            </ButtonAndIconContainer>
          </HeroLeftContainer>

          <HeroRightContainer id="Right">
            <Img
              src={HeroImg}
              alt="hero-image"
            />
          </HeroRightContainer>
        </HeroInnerContainer>
      </HeroContainer>
    </div>
  );
};

export default HeroSection;
