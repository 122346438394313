//import { useState, useEffect } from "react";
import styled from "styled-components";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import GithubIcon from "@mui/icons-material/GitHub";
// import EmailIcon from "@mui/icons-material/Email";
// import { Bio } from "../../data/constants";

const FooterContainer = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  //background: linear-gradient(100.26deg, rgba(0, 102, 255, 0.05) 42.33%, rgba(150, 0, 225, 0.05) 127.07%);
`;

const FooterWrapper = styled.footer`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  padding: 1rem;
  color: ${({ theme }) => theme.text_primary};
`;

// const Logo = styled.h1`
//   font-weight: 600;
//   font-size: 20px;
//   color: ${({ theme }) => theme.primary};
// `;

const Nav = styled.nav`
  width: 100%;
  max-width: 800px;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    text-align: center;
    font-size: 12px;
  }
`;

const NavLink = styled.a`
  color: ${({ theme }) => theme.text_primary};
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

// const SocialMediaIcons = styled.div`
//   display: flex;
//   margin-top: 1rem;
// `;

// const SocialMediaIcon = styled.a`
//   display: inline-block;
//   margin: 0 1rem;
//   font-size: 1.5rem;
//   color: ${({ theme }) => theme.text_primary};
//   transition: color 0.2s ease-in-out;
//   &:hover {
//     color: ${({ theme }) => theme.primary};
//   }
// `;

const Copyright = styled.p`
  margin-top: 4rem;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.soft2};
  text-align: center;
`;

function Footer() {
  // const [visitorCount, setVisitorCount] = useState(0);

  // useEffect(() => {
  //   if (!localStorage.getItem("hasCounted")) {
  //     const count = localStorage.getItem("visitorCount");
  //     const updatedCount = count ? parseInt(count) + 1 : 1;
  //     localStorage.setItem("visitorCount", updatedCount);
  //     localStorage.setItem("hasCounted", "true"); // Flag to prevent multiple increments
  //     setVisitorCount(updatedCount);
  //   }
  // }, []);

  // useEffect(() => {
  //   return () => {
  //     localStorage.removeItem("hasCounted");
  //   };
  // }, []);

  return (
    <FooterContainer>
      <FooterWrapper>
        {/* <Logo>Prasad Deshpande</Logo> */}
        <Nav>
          <NavLink href="#about">About</NavLink>
          <NavLink href="#projects">Projects</NavLink>
          <NavLink href="#experience">Experience</NavLink>
          <NavLink href="#skills">Skills</NavLink>
          <NavLink href="#education">Education</NavLink>
          <NavLink href="#contact">Contact</NavLink>
        </Nav>
        {/* <SocialMediaIcons>
          <SocialMediaIcon
            href={Bio.linkedin}
            target="display">
            <LinkedInIcon />
          </SocialMediaIcon>
          <SocialMediaIcon
            href={Bio.github}
            target="display">
            <GithubIcon />
          </SocialMediaIcon>
          <SocialMediaIcon
            href={`mailto:${Bio.email}`}
            target="display">
            <EmailIcon />
          </SocialMediaIcon>
        </SocialMediaIcons> */}
        <Copyright>Made with ❤️️ by Prasad</Copyright>
      </FooterWrapper>
    </FooterContainer>
  );
}

export default Footer;
