import React from "react";
import { useState } from "react";
import {
  Container,
  Wrapper,
  Title,
  Desc,
  CardContainer,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
} from "./ProjectsStyle";
import ProjectCard from "../Cards/ProjectCards";
import { projects } from "../../data/constants";

const Projects = ({ openModal, setOpenModal }) => {
  const [toggle, setToggle] = useState("all");

  const handleProjectClick = (project) => {
    setOpenModal({ state: true, project: project });
  };

  return (
    <Container id="projects">
      <Wrapper>
        <Title>Projects</Title>
        <Desc>Click on the sub-categories below!</Desc>
        <ToggleButtonGroup>
          {toggle === "all" ? (
            <ToggleButton
              active
              value="all"
              onClick={() => setToggle("all")}>
              All
            </ToggleButton>
          ) : (
            <ToggleButton
              value="all"
              onClick={() => setToggle("all")}>
              All
            </ToggleButton>
          )}
          <Divider />
          {toggle === "fullstack" ? (
            <ToggleButton
              active
              value="fullstack"
              onClick={() => setToggle("fullstack")}>
              Full Stack
            </ToggleButton>
          ) : (
            <ToggleButton
              value="fullstack"
              onClick={() => setToggle("fullstack")}>
              Full Stack
            </ToggleButton>
          )}
          <Divider />
          {toggle === "frontend" ? (
            <ToggleButton
              active
              value="frontend"
              onClick={() => setToggle("frontend")}>
              Frontend
            </ToggleButton>
          ) : (
            <ToggleButton
              value="frontend"
              onClick={() => setToggle("frontend")}>
              Frontend
            </ToggleButton>
          )}
          <Divider />
          {toggle === "backend" ? (
            <ToggleButton
              active
              value="backend"
              onClick={() => setToggle("backend")}>
              Backend
            </ToggleButton>
          ) : (
            <ToggleButton
              value="backend"
              onClick={() => setToggle("backend")}>
              Backend
            </ToggleButton>
          )}
        </ToggleButtonGroup>
        <CardContainer style={{ paddingTop: "20px" }}>
          {toggle === "all" &&
            projects.map((project, index) => (
              <ProjectCard
                key={index}
                project={project}
                openModal={openModal}
                setOpenModal={setOpenModal}
                handleProjectClick={handleProjectClick}
              />
            ))}
          {projects
            .filter((item) => item.category === toggle)
            .map((project, index) => (
              <ProjectCard
                key={index}
                project={project}
                openModal={openModal}
                setOpenModal={setOpenModal}
              />
            ))}
        </CardContainer>
      </Wrapper>
    </Container>
  );
};

export default Projects;
